import axios from './../../request/axios.js'
import api from './../../request/api.js'

const StudentsService = {
  add: function (params) {
    return axios.post(api.Students.add, params)
  },
  update: function (params) {
    return axios.post(api.Students.update, params)
  },
  list: function (params) {
    return axios.post(api.Students.list, params)
  },
  del: function (params) {
    return axios.post(api.Students.del, params)
  },
  count: function (params) {
    return axios.post(api.Students.count, params)
  },
  export: function (params) {
    return axios.post(api.Students.export, params)
  },
  import: function (params) {
    return axios.post(api.Students.import, params)
  },
  imkaramay: function (params) {
    return axios.post(api.Students.imkaramay, params)
  },
  exkaramay: function (params) {
    return axios.post(api.Students.exkaramay, params)
  }
}
export default StudentsService
